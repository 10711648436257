/*
 * @Author: wfj
 * @Date: 2021-03-24 15:53:39
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-02-05 09:59:22
 * @FilePath: /dc-pay-front/src/utils/index.js
 */
import { v4 as uuidv4 } from 'uuid'
import Vue from 'vue'
import {
  ERR_CODE_FRONTEND,
  ENV_DATA,
  rsaPublicKey
} from '@/utils/globalData.js'
import JSEncrypt from 'jsencrypt'

/**
 * @description: 获取url 上的query
 * @param {String} name key值
 * @return {String} 编码后的val
 */
export function getQueryString (name) {
  const searchParams = getURLParameters(location.search)
  const hashParams = getURLParameters(location.hash)
  return searchParams[name] || hashParams[name]

  // const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  // const r = window.location.hash.substring(3).match(reg)
  // if (r !== null) {
  //   return decodeURIComponent(r[2])
  // }
  // return null
}

/**
 * @description: 返回当前地址信息
 * @return {domain: 'pay.9longe.net', topLevelDomain: '9longe.net'}
 */
export function getCurrUrlInfo () {
  const { domain } = document
  const topLevelDomain = domain.split('.').slice(-2).join('.')

  return { domain, topLevelDomain }
}

/**
 * @description: 获取url的参数
 * @param {string} url
 * @return {object} 参数对象
 */
export const getURLParameters = (url) =>
  (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
    (a, v) => (
      /* eslint-disable-next-line */
      (a[v.slice(0, v.indexOf("="))] = v.slice(v.indexOf("=") + 1)), a
    ),
    {}
  )

// 返回地址hash
export function getURLHash (url) {
  if (!url) return ''
  return url.split('#')?.[1] || ''
}

/**
 * @description: 对象转换为 url 参数
 * @param {object} obj {a:1, b:2}
 * @param {string} prefix 前缀，通常为 ? 或 &
 * @return {string} ?a=1&b=2
 */
export function convertObjToUrlParam (obj, prefix = '?') {
  const paramMap = []
  for (const attr in obj) {
    paramMap.push(`${attr}=${obj[attr]}`)
  }
  const urlParam = paramMap.join('&')
  return urlParam ? prefix + urlParam : ''
}

/**
 * @description: 获取前缀转化后的css
 * @param {Object} originCssObj
 * @return {Object}
 */
export function transformCssPrefix (originCssObj) {
  const needPatchProperty = ['transform', 'transition']
  Object.keys(originCssObj).forEach((key) => {
    if (needPatchProperty.includes(key)) {
      const patchKey = `-webkit-${key}`
      originCssObj[patchKey] = originCssObj[key]
    }
  })
  return originCssObj
}

/**
 * @description: 获取客户端信息
 * @return {obj}
 */
export function userAgent () {
  var u = navigator.userAgent

  return {
    // 移动终端浏览器版本信息
    trident: u.indexOf('Trident') > -1, // IE内核
    presto: u.indexOf('Presto') > -1, // opera内核
    webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/Android.*Mobile*/), // 是否为移动终端
    iPad: u.indexOf('iPad') > -1, // 是否iPad
    webApp: u.indexOf('Safari') === -1, // 是否web应该程序，没有头部与底部,
    isiOS: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
    isAndroid: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1,
    isWechat: Boolean(u.match(/MicroMessenger/gi)),
    isSafari: /Safari/.test(u) && !/Chrome/.test(u) && !/CriOS/.test(u),
    isWindows: /windows|win32/i.test(u)
  }
}

/**
 * @description: 清空地址上的参数
 */
export function clearWindowHrefParams () {
  const { origin, pathname, hash } = window.location
  // 参数存在在hash前和hash后
  const routerPath = hash.split('?')[0]
  const finalUrl = origin + pathname + routerPath

  // 第二个参数表示title，0不改变title
  window.history.replaceState({}, 0, finalUrl)
}

/**
 * @description: 返回 vue 实例
 * @return {obj} vue 实例对象
 */
export function getVueVm () {
  return window._myVue
}

/**
 * @description: 防抖函数
 * @param {function} fn 目标函数
 * @param {number} time 等待时间（ms）
 * @return {function} 一个多次执行，仅触发最后一次的函数
 */
export function debounce (fn, time) {
  let timer
  return function (...arg) {
    clearTimeout(timer)
    timer = setTimeout((_) => fn(...arg), time)
  }
}

/**
 * @description: 获取uuid
 * @return {string} uuid
 */
export function getUUID () {
  // 去掉 uuid 的 '-'
  return uuidv4().replace(/-/g, '')
}

/**
 * 点击事件上报
 * @param eventName：事件名称
 * @param event_data
 * @param base_data
 */
export function handleTrack (eventName, event_data = {}, base_data = {}) {
  const vuexInstance = Vue.prototype._store
  if (vuexInstance) {
    vuexInstance.dispatch('tool/sendTrack', {
      eventName,
      event_data,
      base_data
    })
  }
}

// 获取vuex实例
export function getVuexInstance () {
  return Vue?.prototype?._store
}

// 获取router实例
export function getRouterInstance () {
  return getVueVm()?.$router
}

function getRootRem () {
  const fontSize = window.getComputedStyle(document.body).fontSize
  try {
    const value = parseInt(fontSize)
    return value
  } catch {
    return 50
  }
}

/**
 * @description: 往指定dom插入节点
 * @param {number} height 节点高度
 * @param {object} {
 *  dom: 插入节点
 *  domPosition: 插入位置：before 在dom头部；after 在dom末尾
 * }
 */
export function insertStrutDomToBody (
  height,
  { dom = document.body, domPosition = 'after' }
) {
  const element = document.createElement('div')
  // const { mobile, iPad } = userAgent()

  // if (mobile && !iPad) {
  //   element.style.height = `${height / 50}rem`
  // } else {
  //   element.style.height = `${height}PX`
  // }

  const unit = getRootRem()
  element.style.height = `${height / unit}rem`

  if (domPosition === 'after') {
    dom.appendChild(element)
  } else {
    const firstChild = dom.firstChild
    dom.insertBefore(element, firstChild)
  }
  return element
}

/**
 * @description: 重试方法
 * @param {function} fn 目标函数（需返回promise）
 * @param {number} times 重试次数
 * @param {number} delay 重试时间间隔，单位ms
 * @return {promise}
 */
export function useRetryFn (fn, times, delay) {
  return new Promise(function (resolve, reject) {
    function attempt () {
      fn()
        .then(resolve)
        .catch(function (err) {
          if (times === 0) {
            reject(err)
          } else {
            times--
            setTimeout(attempt, delay)
          }
        })
    }
    attempt()
  })
}

/**
 * @description 当接口返回的指定的错误码时，重试方法
 * @param {Functrion} fn 目标函数
 * @param {number} times 重试次数
 * @param {number} delay 重试间隔
 * @param {number[]} errorCodes 接口返回的错误码
 */
export function useRetryFnWhenMatchErrorCodes (
  fn,
  times,
  delay,
  errorCodes = [212]
) {
  return new Promise(function (resolve, reject) {
    let timer = 0
    function attempt () {
      fn()
        .then(resolve)
        .catch(function (err) {
          if (times === 0) {
            if (timer) clearTimeout(timer)
            reject(err)
          } else {
            if (errorCodes.includes(err.code)) {
              times--
              if (timer) clearTimeout(timer)
              timer = setTimeout(attempt, delay)
            } else {
              if (timer) clearTimeout(timer)
              reject(err)
            }
          }
        })
    }
    attempt()
  })
}

export const disposeStatusCode = (code) => {
  if (code && typeof code === 'string') {
    code = ERR_CODE_FRONTEND[code] || code
  }
  return code
}

// windows && pc端下滚动条很粗，视情况隐藏滚动条
export function validateHideScroll () {
  const { iPad, mobile, isWindows } = userAgent()
  const isMobile = iPad || mobile
  return !isMobile && isWindows
}

export class UrlScheme {
  static isSchemeUrl (url) {
    return typeof url === 'string' && !url.startsWith('http')
  }

  /**
   * @description: 打开scheme地址
   * @param {string} wakeupUrl 目标地址
   * @param {function} onBeforeWakeup 唤起前回调
   * @param {function} onWakeupFail 唤起失败回调
   */
  static openSchemeUrl (wakeupUrl, onBeforeWakeup, onWakeupFail) {
    var ifm = document.createElement('iframe')

    ifm.setAttribute('src', wakeupUrl)
    ifm.setAttribute('style', 'display:none')
    document.body.appendChild(ifm)
    onBeforeWakeup && onBeforeWakeup()

    var currentTime = Date.now()

    setTimeout(function () {
      var nowTime = Date.now()
      if (nowTime - currentTime < 1050) {
        onWakeupFail && onWakeupFail()
      }
    }, 1000)
  }
}

// rsa 加密密码
export function encodePwd (pwd) {
  return encodeValue(pwd, rsaPublicKey)
}

// rsa 加密
export function encodeValue (value, publicKey) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey)
  return encryptor.encrypt(value)
}

export class Base64 {
  /**
   * @description: base64加密
   * @param {string | object} target
   * @return {string}
   */
  static encode (target) {
    return encodeURIComponent(window.btoa(JSON.stringify(target)))
  }

  /**
   * @description: base64解密
   * @param {string} target 通过Base64.encode加密过的数据
   * @return {string | object}
   */
  static decode (target) {
    return JSON.parse(window.atob(decodeURIComponent(target)))
  }
}

/** 在新窗口打开链接 */
export function openLinkOnNewWindow (url) {
  const id = 'superLabel'
  let el = document.getElementById(id)
  if (el) {
    el.setAttribute('href', url)
  } else {
    el = document.createElement('a')
    el.setAttribute('href', url)
    el.setAttribute('target', '_blank')
    el.setAttribute('id', id)
    document.body.appendChild(el)
  }
  el.click()
}

// 动态加载js
export function loadScript (url, callback, errCallback) {
  const element = document.createElement('script')
  element.src = url
  element.onload = function () {
    callback && callback()
  }
  element.onerror = function (err) {
    errCallback && errCallback(err)
  }

  document.body.appendChild(element)
}

export class TimeCount {
  constructor () {
    this.nowTimestamp = 0
  }

  getCurrTimestamp () {
    return +new Date()
  }

  time () {
    this.nowTimestamp = this.getCurrTimestamp()
  }

  timeEnd () {
    return this.getCurrTimestamp() - this.nowTimestamp
  }
}

export function triggerClick (el) {
  el && el.click()
}

// 用于跨组件通讯
export const eventBus = new Vue()
