/*
 * @Date: 2021-12-10 17:52:18
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2025-03-20 14:32:11
 * @Describes: 全局静态配置（避免引用其他js，可能存在循环引用问题）
 */

const VUE_APP_ENV = process.env.VUE_APP_ENV
const ENV_DATA = {
  isDev: !VUE_APP_ENV || VUE_APP_ENV === 'dev',
  isSit: VUE_APP_ENV === 'sit',
  isPre: VUE_APP_ENV === 'pre',
  isPro: VUE_APP_ENV === 'pro',
  currEnv: VUE_APP_ENV
}

const { currEnv } = ENV_DATA
class LanguageData {
  static languageMap = {
    'zh-cn': {
      key: 'zh-cn',
      value: '简体',
      // checkout语言标识映射
      checkoutKey: 'ZH-CH',
      pingpongKey: 'zh',
      // airwallex 语言标识：https://github.com/airwallex/airwallex-payment-demo/tree/master/docs#initialize-airwallex-1
      airwallexKey: 'zh'
    },
    'zh-tw': {
      key: 'zh-tw',
      value: '繁體',
      checkoutKey: 'ZH-TW',
      pingpongKey: 'tw',
      airwallexKey: 'zh'
    },
    'en-us': {
      key: 'en-us',
      value: 'English',
      checkoutKey: 'EN-GB',
      pingpongKey: 'en',
      airwallexKey: 'en'
    },
    'ko-kr': {
      key: 'ko-kr',
      value: '한국어',
      checkoutKey: 'KO-KR',
      pingpongKey: 'kr',
      airwallexKey: 'ko'
    },
    'ja-jp': {
      key: 'ja-jp',
      value: '日本語',
      checkoutKey: 'JA-JP',
      pingpongKey: 'jp',
      airwallexKey: 'ja'
    },
    // 泰语
    'th-th': {
      key: 'th-th',
      value: 'ไทย',
      checkoutKey: 'TH-TH',
      pingpongKey: 'th'
    },
    // 俄
    'ru-ru': {
      key: 'ru-ru',
      value: 'русский',
      checkoutKey: '',
      pingpongKey: 'ru'
    },
    // 马来
    'ms-my': {
      key: 'ms-my',
      value: 'Melayu',
      checkoutKey: 'MS-MY'
    },
    // 越南
    'vi-vn': {
      key: 'vi-vn',
      value: 'Tiếng Việt',
      checkoutKey: 'VI-VN',
      pingpongKey: 'vi'
    },
    // 印度
    'id-id': {
      key: 'id-id',
      value: 'bahasa Indonesia',
      checkoutKey: 'ID-ID',
      pingpongKey: 'id'
    },
    // 土耳其
    'tr-tr': {
      key: 'tr-tr',
      value: 'Türk',
      checkoutKey: '',
      pingpongKey: 'tr'
    },
    // 德语
    'de-de': {
      key: 'de-de',
      value: 'Deutsch',
      checkoutKey: 'DE-DE',
      pingpongKey: 'de',
      airwallexKey: 'de'
    },
    // 阿拉伯
    'ar-sa': {
      key: 'ar-sa',
      value: 'عربي',
      checkoutKey: 'AR',
      airwallexKey: 'ar'
    },
    // 葡萄牙
    'pt-pt': {
      key: 'pt-pt',
      value: 'português',
      checkoutKey: '',
      pingpongKey: 'pt'
    },
    // 波斯语（暂时未添加翻译文件）
    'fa-fa': {
      key: 'fa-fa',
      value: 'فارسی',
      checkoutKey: ''
    },
    // 罗马尼亚语（暂时未添加翻译文件）
    'ro-ro': {
      key: 'ro-ro',
      value: 'Română',
      checkoutKey: '',
      pingpongKey: 'ro'
    },
    // 法国
    'fr-fr': {
      key: 'fr-fr',
      value: 'Français',
      checkoutKey: 'FR-FR',
      pingpongKey: 'fr',
      airwallexKey: 'fr'
    },
    // 意大利
    'it-it': {
      key: 'it-it',
      value: 'Italiano',
      checkoutKey: 'IT-IT',
      pingpongKey: 'it',
      airwallexKey: 'it'
    },
    // 波兰
    'pl-pl': {
      key: 'pl-pl',
      value: 'Polski',
      pingpongKey: 'pl',
      checkoutKey: ''
    }
  };

  static serviceEmailMap = {
    // 国内
    wsyInland: 'kf@dianchu.com',
    // 海外
    wsyOutland: 'cs@clicktouch.cc',
    // 大清
    dqOutland: 'kf@dianchu.com',
    // 英雄之地
    hero: 'helpherosland@gmail.com'
  };

  static getLangKey (languageMapKey) {
    return this.languageMap[languageMapKey]?.key
  }

  static getLangMap (keys) {
    const arr = []
    keys.forEach((key) => {
      if (this.languageMap[key]) {
        arr.push(this.languageMap[key])
      }
    })
    return arr
  }
}

// 统一处理环境相关配置
const ENV_CONFIG = {
  default: {
    'vk-global-region': ['11087', '1084'],
    'dzb-tw-region': ['10012'],
    // checkout 渠道的公钥
    'checkout-public-key': {
      gok: 'pk_sbox_qmowrrsn3jkvthlcthe6gickje2'
    },
    'checkout-risk-sdk-url':
      'https://risk.sandbox.checkout.com/cdn/risk/1/risk.js',
    'gok-us-region': ['200100'],
    'dq-tw-region': ['1014'],
    'herobump-global-region': ['1999'],
    'hero-global-region': [
      '221110010',
      '221110011',
      '221110012',
      '221110018',
      '221110019',
      '221110020'
    ],
    'hero-cn-region': ['221110003'],
    'hero-audit-region': ['221110003'],
    'hero-steam-region': ['221110002', '221110003'],
    'xsolla-pay-url': 'https://sandbox-secure.xsolla.com/paystation3/'
  },
  sit: {
    'vk-global-region': ['11087', '1084'],
    'dzb-tw-region': ['10012'],
    'checkout-public-key': {
      gok: 'pk_sbox_qmowrrsn3jkvthlcthe6gickje2'
    },
    'checkout-risk-sdk-url':
      'https://risk.sandbox.checkout.com/cdn/risk/1/risk.js',
    'gok-us-region': ['200100'],
    'dq-tw-region': ['1014'],
    'herobump-global-region': ['1999'],
    'hero-global-region': ['221110003'],
    'hero-cn-region': ['221110003'],
    'hero-audit-region': ['221110003'],
    'hero-steam-region': ['221110002', '221110003'],
    'xsolla-pay-url': 'https://sandbox-secure.xsolla.com/paystation3/'
  },
  pre: {
    'vk-global-region': ['11087'],
    'dzb-tw-region': ['10012'],
    'checkout-public-key': {
      gok: 'pk_sbox_qmowrrsn3jkvthlcthe6gickje2'
    },
    'checkout-risk-sdk-url':
      'https://risk.sandbox.checkout.com/cdn/risk/1/risk.js',
    'gok-us-region': ['200101', '200102', '200103'],
    'dq-tw-region': ['1014', '1005'],
    'herobump-global-region': ['3004'],
    'hero-global-region': ['221110003'],
    'hero-cn-region': ['221110003'],
    'hero-audit-region': ['221110003'],
    'hero-steam-region': ['221110005', '221110010', '221110011', '221110012'],
    'xsolla-pay-url': 'https://sandbox-secure.xsolla.com/paystation3/'
  },
  pro: {
    'vk-global-region': ['11090', '11088', '11085'],
    'dzb-tw-region': ['10011'],
    'checkout-public-key': {
      gok: 'pk_5n2iw2xgwv3alfxu5i4glkuzxi5'
    },
    'checkout-risk-sdk-url': 'https://risk.checkout.com/cdn/risk/1/risk.js',
    'gok-us-region': ['200101', '200102', '200103'],
    'dq-tw-region': ['1005'],
    'herobump-global-region': ['3009'],
    'hero-global-region': [
      '221110005',
      '221110010',
      '221110011',
      '221110012',
      '221110018',
      '221110020'
    ],
    'hero-cn-region': ['221110002', '221110005'],
    'hero-audit-region': ['221110003'],
    // 'hero-steam-region': ['221110005', '221110010', '221110011', '221110012'],
    'hero-steam-region': ['221110005'],
    'xsolla-pay-url': 'https://secure.xsolla.com/paystation3/'
  }
}
const currEnvConfig = ENV_CONFIG[currEnv] || ENV_CONFIG.default

const genImgUrl = (imgName) => require(`@/assets/game-logo/${imgName}.png`)

/**
 * 域名、服务配置（配置于 versions ，指定版本信息）
 {
  id,
  host, // 域名（限制版本仅允许 host 域名访问）
  fetchBaseUrl, // axios 的 baseUrl ，决定请求哪个后端服务
  turntableActProductionUrl, // 微前端请求服务（大转盘活动）
  wechatAppId, // 微信appid (通常不同域名不同appid)
 }
 */
const SERVER_REGION_MAP = {
  // 欧洲
  eu: {
    id: Symbol('eu'),
    host: 'pay.9longe.net',
    fetchBaseUrl: '/eu/webpayserver',
    turntableActProductionUrl: 'https://activity-eu.clktec.com/turntable/'
  },
  // 国内
  cn: {
    id: Symbol('cn'),
    host: 'pay-cn.9longe.net',
    fetchBaseUrl: '/cn/webpayserver',
    turntableActProductionUrl: 'https://activity.dianchu.com/turntable/',
    wechatAppId: 'wx99a7e67584565f7d'
  },
  // 国内-御长风(与国内cn一样，仅域名不同)
  cn_yuchangfeng: {
    id: Symbol('cn_yuchangfeng'),
    host: 'gameclub-cn.yuchangfeng.net',
    fetchBaseUrl: '/cn/webpayserver',
    turntableActProductionUrl: 'https://activity.dianchu.com/turntable/',
    // TODO: 待配置（英勇之地-国内需要）
    wechatAppId: ''
  },
  // 香港
  hk: {
    id: Symbol('hk'),
    host: 'pay-hk.9longe.net',
    fetchBaseUrl: '/hk/webpayserver',
    turntableActProductionUrl: 'https://activity.clktec.com/turntable/'
  },
  // 英勇香港
  hk_herosland: {
    id: Symbol('hk_herosland'),
    host: 'payment.herosland.io',
    fetchBaseUrl: '/hk/webpayserver',
    turntableActProductionUrl: 'https://activity.clktec.com/turntable/'
  },
  // 维京欧洲
  eu_vk: {
    id: Symbol('eu_vk'),
    host: 'payment-vikingard.clktec.com',
    fetchBaseUrl: '/eu/webpayserver',
    turntableActProductionUrl: 'https://activity-eu.clktec.com/turntable/'
  },
  // 维京审核（该域名不对用户暴露）
  eu_vkAudit: {
    id: Symbol('eu_vkAudit'),
    host: 'vkp.clktec.com',
    fetchBaseUrl: '/eu/webpayserver',
    turntableActProductionUrl: 'https://activity-eu.clktec.com/turntable/'
  }
}
// 三方登入类型枚举
const THIRD_PARTY_LOGIN_TYPE = {
  steam: 'steam',
  wechat: 'wechat',
  facebook: 'facebook',
  google: 'google'
}
const COMPANY_MAP = {
  dc: 1, // 点触科技
  xyy: 6, // 逍遥游
  yqf: 7, // 御长风
  dfyq: 9 // 东方有趣
}
/**
 * @description: 游戏的版本配置
 * @type {
    name: string,
    region: string[],
    gameLogo: string,
    // 服务、域名配置
    serverRegion: SERVER_REGION_MAP,
    website?: string,
    serviceEmail?: string,
    // 支持第三方登录
    thirdPartyLogin?: THIRD_PARTY_LOGIN_TYPE[],
    // 无购物车模式
    withoutShoppingCartMode?: boolean,
    // 唤起结算模式（其他页面无法访问）
    onlyPaymentPageMode?: boolean,
    // 首页页脚的版权文案
    copyRightText?: string,
    // 首页页脚第一个公司主体logo(支持按语言区分)
    companyLogo?: { default: string, 'zh-cn': string }
    // 首页页脚第二个公司主体logo(支持按语言区分)
    companyLogoSecond?: { default: string, 'zh-cn': string }
    // 公司主体
    companyId?: number, // 不配置默认为 COMPANY_MAP.dc
  }
 */
const versions = {
  wsy: {
    tw: {
      name: '国际',
      // 游戏大区
      region: ['1012'],
      gameLogo: genImgUrl('tw'),
      website: 'https://wsygj.dianchu.com',
      serviceEmail: LanguageData.serviceEmailMap.wsyInland,
      serverRegion: SERVER_REGION_MAP.eu
    },
    kr: {
      name: '韩国',
      // 游戏大区
      region: ['1013'],
      gameLogo: genImgUrl('kr'),
      website: 'https://emperor.clicktouch.cc/',
      serviceEmail: LanguageData.serviceEmailMap.wsyOutland,
      serverRegion: SERVER_REGION_MAP.eu
    },
    jp: {
      name: '日本',
      // 游戏大区
      region: ['1015'],
      gameLogo: genImgUrl('jp'),
      website: 'https://emperorjp.clicktouch.cc/',
      serviceEmail: LanguageData.serviceEmailMap.wsyOutland,
      serverRegion: SERVER_REGION_MAP.eu
    },
    th: {
      name: '泰国',
      // 游戏大区
      region: ['1033'],
      gameLogo: genImgUrl('th'),
      website: 'https://emperorth.clicktouch.cc/',
      serviceEmail: LanguageData.serviceEmailMap.wsyOutland,
      serverRegion: SERVER_REGION_MAP.eu
    },
    ru: {
      name: '俄罗斯',
      // 游戏大区
      region: ['1035'],
      gameLogo: genImgUrl('ru'),
      website: 'https://emperorru.clicktouch.cc/',
      serviceEmail: LanguageData.serviceEmailMap.wsyOutland,
      serverRegion: SERVER_REGION_MAP.eu
    },
    id: {
      name: '印马',
      // 游戏大区
      region: ['1036'],
      gameLogo: genImgUrl('id'),
      website: 'https://emperorid.clicktouch.cc/',
      serviceEmail: LanguageData.serviceEmailMap.wsyOutland,
      serverRegion: SERVER_REGION_MAP.eu
    },
    cn: {
      name: '中国大陆',
      // 游戏大区
      // region: ['1010', '1011'],
      region: ['1010'],
      gameLogo: genImgUrl('cn'),
      website: 'https://wsy.dianchu.com/dcHome/',
      serviceEmail: LanguageData.serviceEmailMap.wsyInland,
      serverRegion: SERVER_REGION_MAP.cn
    },
    en: {
      name: '英文',
      // 游戏大区
      region: ['1030', '1031', '1032'],
      gameLogo: genImgUrl('en'),
      website: 'https://emperoren.clicktouch.cc/',
      serviceEmail: LanguageData.serviceEmailMap.wsyOutland,
      serverRegion: SERVER_REGION_MAP.eu
    },
    fr: {
      name: '法国',
      // 游戏大区
      region: ['1038'],
      gameLogo: genImgUrl('fr'),
      website: 'https://emperoren.clicktouch.cc/',
      serviceEmail: LanguageData.serviceEmailMap.wsyOutland,
      serverRegion: SERVER_REGION_MAP.eu
    }
  },
  gos: {
    // 全球
    global: {
      name: '全球',
      // 游戏大区
      region: ['1020', '1022', '1023'],
      gameLogo: genImgUrl('gos_normal'),
      website: 'https://gos.clicktouch.cc/',
      serviceEmail: '',
      serverRegion: SERVER_REGION_MAP.eu
    }
  },
  gok: {
    // 欧洲
    us: {
      name: '欧洲',
      // 游戏大区
      region: currEnvConfig['gok-us-region'],
      gameLogo: genImgUrl('gok_us'),
      website: 'https://gok.clktec.com/',
      serviceEmail: '',
      serverRegion: SERVER_REGION_MAP.eu
    }
  },
  dq: {
    tw: {
      name: '国际',
      // 游戏大区
      region: currEnvConfig['dq-tw-region'],
      gameLogo: genImgUrl('dq_tw'),
      website: 'https://dq.dianchu.com/home',
      serviceEmail: '',
      serverRegion: SERVER_REGION_MAP.hk
    }
  },
  dzb: {
    tw: {
      name: '国际',
      // 游戏大区
      region: currEnvConfig['dzb-tw-region'],
      gameLogo: genImgUrl('dzb_tw'),
      website: 'https://anchortw.clktec.com/',
      serviceEmail: LanguageData.serviceEmailMap.wsyInland,
      serverRegion: SERVER_REGION_MAP.hk,
      companyId: COMPANY_MAP.xyy
    }
  },
  herobump: {
    global: {
      name: '通用',
      // 游戏大区
      region: currEnvConfig['herobump-global-region'],
      gameLogo: genImgUrl('herobump_global'),
      website: '',
      serviceEmail: '',
      serverRegion: SERVER_REGION_MAP.eu,
      companyId: COMPANY_MAP.dfyq
    }
  },
  hero: {
    // TODO: 外部审核版，不对用户开放（完成后删除）
    audit: {
      name: '审核版本',
      region: currEnvConfig['hero-audit-region'],
      gameLogo: genImgUrl('hero_land'),
      serviceEmail: LanguageData.serviceEmailMap.hero,
      serverRegion: SERVER_REGION_MAP.cn_yuchangfeng,
      copyRightText:
        'COPYRIGHT© 2024 厦门御长风网络科技有限公司.ALL RIGHTS RESERVED.',
      companyLogo: {
        default: 'company_logo_yuchangfeng_cn'
      },
      companyId: COMPANY_MAP.yqf
    },
    global: {
      name: '香港',
      region: currEnvConfig['hero-global-region'],
      gameLogo: genImgUrl('hero_steam'),
      serverRegion: SERVER_REGION_MAP.hk_herosland,
      onlyPaymentPageMode: false,
      withoutShoppingCartMode: false,
      companyId: COMPANY_MAP.dfyq
    },
    cn: {
      name: '中国大陆',
      region: currEnvConfig['hero-cn-region'],
      gameLogo: genImgUrl('hero_land'),
      serverRegion: SERVER_REGION_MAP.cn_yuchangfeng,
      onlyPaymentPageMode: true,
      companyId: COMPANY_MAP.yqf
    },
    steam: {
      name: 'steam',
      region: currEnvConfig['hero-steam-region'],
      gameLogo: genImgUrl('hero_steam'),
      serviceEmail: LanguageData.serviceEmailMap.hero,
      serverRegion: SERVER_REGION_MAP.eu,
      thirdPartyLogin: [THIRD_PARTY_LOGIN_TYPE.steam],
      withoutShoppingCartMode: true,
      companyId: COMPANY_MAP.dfyq
    }
  },
  vk: {
    global: {
      name: '欧洲',
      // 游戏大区
      region: currEnvConfig['vk-global-region'],
      gameLogo: genImgUrl('vk_global'),
      website: '',
      serviceEmail: '',
      serverRegion: SERVER_REGION_MAP.eu_vk,
      companyId: COMPANY_MAP.dfyq
    },
    // 仅用于审核
    audit: {
      name: '欧洲-审核',
      region: currEnvConfig['vk-global-region'],
      gameLogo: genImgUrl('vk_global'),
      website: '',
      serviceEmail: '',
      serverRegion: SERVER_REGION_MAP.eu_vkAudit,
      companyId: COMPANY_MAP.dfyq
    }
  }
}

// 游戏配置
const gameConfigMap = {
  // 默认配置（游戏未配置时则使用默认配置）
  default: {
    title: 'dianchu-pay',
    copyRightText:
      'Copyright © 2014 - 2024 DIANCHU Technology. All Right Reserved.',
    noticeBoardTextKey: 'noticeSimple',
    companyLogo: {
      default: 'company_logo_outlang',
      'zh-cn': 'company_logo_inlang'
    }
  },
  wsy: {
    noticeBoardTextKey: 'notice',
    activityConfig: {
      gameId: 104,
      // 活动公钥
      rsaPublicKey: `-----BEGIN RSA PUBLIC KEY-----
      MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQD1D9seHL148M35OPFnN9MfRbcs
      PaV9wXG44Kx7Jun1XIb/HnHgidh/LuYwdzgJzR6LSeLe6m+yNQjcKuVAOpbrJDU7
      OuF/DmLRg2AGCUEU9DEvwKGPCxUXVjQqD3/jA3IuEPckFR1QvU0YbvnuzaIUyTpK
      MhCfDIo3LAV2g2pysQIDAQAB
      -----END RSA PUBLIC KEY-----`
    }
  },
  gos: {
    noticeBoardTextKey: 'noticeSimple',
    companyLogo: {
      default: 'company_logo_vertical_outlang',
      'zh-cn': 'company_logo_vertical_inlang'
    },
    companyLogoSecond: {
      default: 'company_logo_mengjia'
    },
    activityConfig: {
      gameId: 108,
      // 活动公钥
      rsaPublicKey: `-----BEGIN RSA PUBLIC KEY-----
      MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDs1sAvGTd014jyb3pjCttT0unn
      xmXv6vKHBd57/Uodt4pzQ8v5juMInJuBQyI8isd+BlXrTXEH+uxoU3/3nAJeoCYe
      umZQc8/S2EW2kZg79xAiE1p9bmiRFgWSA1auk2Vz3W5DaR7uMeKOrRw46CuMqihP
      oX7RCsLwQa4uniGzcQIDAQAB
      -----END RSA PUBLIC KEY-----`
    }
  },
  gok: {
    copyRightText:
      'Copyright2013-2024 Mechanist Games. All Rights Reserved By Mechanist Games.',
    noticeBoardTextKey: 'noticeSimple',
    companyLogo: {
      default: 'company_logo_vertical_outlang',
      'zh-cn': 'company_logo_vertical_inlang'
    },
    companyLogoSecond: {
      default: 'company_logo_mengjia'
    },
    activityConfig: {
      gameId: 1563952224,
      // 活动公钥
      rsaPublicKey: `-----BEGIN PUBLIC KEY-----
      MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtyPxEkF7ztLrAd/0yvXl
      EeegKUwEQid/7qzx2XQOcJKmHhHxoDEJtLkADnsu6IRmP9S4SkA0xbJizyNcVWce
      8VsIsfF8qs/8I9UuUlztFuj2rrvqNSfdHkiLOSlUhbmFTVEDfLRDowDlSCDAoRgN
      ZX7NQfxK4SjopZepa2oxpdC7+72HUtMA3JQkmJHzK4p/BXo4TD6mNo1NTtAeHYbJ
      xkouFpGhYeLydLRaw28AYPPH1YiRSfn04Zpcmj7w8ipGQRNgMzqWBktYim9mKnC3
      9a0STKAg4h3aUV2d9Ta8ztQvMZp4QcqUpa11nj/FhDiLwsdsZbhjqSH5+mU4b5J0
      +wIDAQAB
      -----END PUBLIC KEY-----`
    }
  },
  dq: {
    noticeBoardTextKey: 'notice'
  },
  dzb: {
    copyRightText:
      'Copyright © 2019 - 2024 Joyager International Company Limited. All Right Reserved.',
    noticeBoardTextKey: 'notice',
    companyLogo: {
      default: 'company_logo_dzb_en',
      'zh-cn': 'company_logo_dzb_cn'
    }
  },
  hero: {
    title: 'pay',

    gameTitleI18nKey: 'thirdPartyLogin.gameNameByHero',
    noticeBoardTextKey: 'noticeSimpleByHero',
    copyRightText:
      'Copyright ©2024 Orienjoy International Company Limited.All rights reserverd.',
    companyLogo: {
      default: 'company_logo_dfyq_en',
      'zh-cn': 'company_logo_dfyq'
    },
    couponDisable: true,
    stepBarDisable: true
  },
  herobump: {
    copyRightText:
      'Copyright © 2014 - 2024 DIANCHU Technology. All Right Reserved.',
    noticeBoardTextKey: 'notice',
    companyLogo: {
      default: 'company_logo_dfyq_en'
    }
  },
  vk: {
    noticeBoardTextKey: 'noticeSimple',
    copyRightText:
      'Copyright ©2024 Orienjoy International Company Limited.All rights reserverd.',
    companyLogo: {
      default: 'company_logo_dfyq_en',
      'zh-cn': 'company_logo_dfyq'
    },
    stepBarDisable: true
  }
}

const REDIRECT_HOSTNAME = 'https://pay.9longe.net'

const payIdMap = {
  coda: '10013',
  adyen: '10015',
  ali: '10016',
  wx: '10017',
  mayi: '10018',
  xsolla: '10020',
  paypal: '10232',
  paymentwall: '10235',
  payermax: '10238',
  checkout: '10239',
  mycard: '10244',
  airwallex: '10247',
  steam: '10250',
  pingpong: '10255',
  dukpay: '10259'
}

const THIRD_STATUS_MAP = {
  301: '支付 sdk 调用服务器失败',
  302: '参数缺失',
  303: '通用的包罗万象的错误',
  304: '取消支付',
  305: '未返回url给到前端',
  306: '支付被拒绝'
}

// 前端 Socket 常见连接错误
const ERR_CODE_FRONTEND = {
  ECONNABORTED: 320, // 软件引起的连接中止
  ECONNRESET: 321, // 对方复位连接
  ETIMEDOUT: 322, // 连接超时
  EPIPE: 323 // 管道破裂
}

const urlConfig = {
  // 重定向配置
  redirect: {
    [payIdMap.mayi]: `${location.origin}${location.pathname}`,
    [payIdMap.coda]: `${REDIRECT_HOSTNAME}/redirect.html`,
    /**
     * @description: 重定向中转页 url
     * 为什么不是 location.href ?
     * 答：1.coda 支付重定向地址需写死在后台，其他支付则是通过创建订单时动态传入；
     * 2.支付网页是以地址后缀区分多版本（例：wsy-cn、wsy-tw）；
     * 综合以上，为了避免差异化，所以统一所有支付都通过 redirectUrl 进行重定向。
     * 注：该页面存放于【public/redirect.html，pages/redirect.js】
     */
    redirectUrl: `${REDIRECT_HOSTNAME}/redirect.html`,
    // 默认重定向地址
    defaultCallbackUrl: `${REDIRECT_HOSTNAME}${location.pathname}`,
    // 首页，如：'https://pay.9longe.net/wsy-kr/'（不带路由即路由为'/'）
    homeUrl: location.origin + location.pathname + '#/',
    // 支付成功页
    successPageUrl: location.origin + location.pathname + '#/paySuccess',
    homeUrlNoHash: location.origin + location.pathname,
    currHref: window.location.href
  },
  /**
   * 微信授权中转地址（文档地址：https://xmdc.yuque.com/qgosqx/dsd76u/enkvn0）
   * @param {string} urlParamStr querystring { appid, redirect_uri, response_type, scope, state }
   * @returns {string} 地址
   */
  getWXAuthUrl (urlParamStr) {
    /**
     * urlParamStr 包含字段

     */
    return `https://wx-login.dianchu.com/?${urlParamStr}#wechat_redirect`
  },
  /**
   * @description: 获取 xsolla 支付页 url
   * @param {string} accessToken
   * @return {string} url
   */
  getXsollaPayUrl (accessToken) {
    const hostname = currEnvConfig['xsolla-pay-url']
    return `${hostname}?access_token=${accessToken}`
  }
}

const pageConfig = {
  // 不显示顶部导航的页面
  hideNavBarRouters: [
    '/',
    '/cart',
    '/coupon',
    '/micro-app/haggle',
    '/paySuccess'
  ]
}

const shoppingCartConfig = {
  maxLength: 30
}
const favoriteConfig = {
  maxLength: 50
}

// 密码公钥
const rsaPublicKey = `-----BEGIN RSA PUBLIC KEY-----
MDwwDQYJKoZIhvcNAQEBBQADKwAwKAIhAKFrAaUF6vrOKTH/GLW6FIAR4NaYDJ3r
dSAw8ZCFTPuRAgMBAAE=
-----END RSA PUBLIC KEY-----`

export {
  rsaPublicKey,
  currEnvConfig,
  versions,
  urlConfig,
  payIdMap,
  gameConfigMap,
  shoppingCartConfig,
  favoriteConfig,
  pageConfig,
  THIRD_STATUS_MAP,
  ENV_DATA,
  ERR_CODE_FRONTEND,
  SERVER_REGION_MAP,
  THIRD_PARTY_LOGIN_TYPE,
  LanguageData,
  COMPANY_MAP
}
